import Console from "../console";
import ApiHelper from "./apihelper";

class OrganisationEventHelper {

  static sendReportOpened(organisationId, reportCreated) {
    OrganisationEventHelper.sendEvent(
      "reportOpened",
      organisationId,
      reportCreated,
      null,
      null,
      null
    );
  }

  static sendAddedPhone(organisationId, reportCreated, phone) {
    OrganisationEventHelper.sendEvent(
      "addedPhone",
      organisationId,
      reportCreated,
      null,
      phone,
      null
    );
  }

  static sendTakeDeal(organisationId, reportCreated, dealSortKey) {
    OrganisationEventHelper.sendEvent(
      "takeDeal",
      organisationId,
      reportCreated,
      null,
      null,
      dealSortKey
    );
  }

  static sendOpenedTakeDeal(organisationId, reportCreated, dealSortKey) {
    OrganisationEventHelper.sendEvent(
      "openedTakeDeal",
      organisationId,
      reportCreated,
      null,
      null,
      dealSortKey
    );
  }

  static sendRequestRefresh(organisationId) {
    OrganisationEventHelper.sendEvent(
      "requestRefresh",
      organisationId,
      null,
      null,
      null,
      null
    );
  }

  static sendRequestCallback(organisationId) {
    OrganisationEventHelper.sendEvent(
      "requestCallback",
      organisationId,
      null,
      null,
      null,
      null
    );
  }

  static async sendEvent(
    type,
    organisationId,
    reportCreated,
    emails,
    phone,
    dealSortKey
  ) {
    try {
      const client = await ApiHelper.http();
      const response = await client.post(
        `${ApiHelper.endPoint()}organisations/${organisationId}/events`,
        OrganisationEventHelper.buildParams(
          type,
          reportCreated,
          emails,
          phone,
          dealSortKey
        )
      );

      if (response.data.status === "success") {
        Console.log("Successfully submitted event");
      } else {
        Console.error("Failed to submit event");
      }
    } catch (err) {
      Console.error(err);
    }
  }

  static buildParams(type, reportCreated, emails, phone, dealSortKey) {
    const params = [];

    if (type) {
      params.push("type=" + type);
    }

    if (reportCreated) {
      params.push("savingsReportCreated=" + reportCreated);
    }

    if (emails && emails.length > 0) {
      for (const email of emails) {
        params.push("email=" + encodeURIComponent(email));
      }
    }

    if (phone) {
      params.push("phoneNumber=" + encodeURIComponent(phone));
    }

    if (dealSortKey) {
      params.push("dealSortKey=" + encodeURIComponent(dealSortKey));
    }

    return params.join("&");
  }
}

export default OrganisationEventHelper;
