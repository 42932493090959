<template>
  <button :disabled="busy" class="signin-btn mx-2 gtm-signup" v-on:click="signIn">
    <img class="mr-1 signin-btn-img" v-bind:src="buttonImageUrl" />
    <span class="mr-1 signin-btn-text">{{ buttonText }}</span>
  </button>
</template>

<style lang="scss">
@import "@/styles/colours.scss";
.signin-btn-img {
  height: 40px;
}
.signin-btn-text {
  color: $color-font-headings;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}
.signin-btn {
  border: 1px solid $color-grey;
  background-color: white;
  margin-bottom: 12px;
  min-width: 245px;
}
</style>

<script>
import { cognito } from "@/config/reducerproperties";
import Console from "@/console";

export default {
  name: "AuthButton",
  props: {
    buttonImageUrl: String,
    buttonText: String,
    cognitoProvider: String,
    busy: Boolean
  },
  methods: {
    signIn() {
      this.$emit("updateBusy", true);
      var signInUrl =
        cognito.endPoint +
        "/oauth2/authorize?identity_provider=" +
        this.cognitoProvider +
        "&redirect_uri=" +
        cognito.callBackUrl +
        "&response_type=code&client_id=" +
        cognito.clientId +
        "&scope=openid+aws.cognito.signin.user.admin";
      Console.log(signInUrl);
      window.location.href = signInUrl;
    }
  }
};
</script>