<template>
  <div id="authentication">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <b-container>
      <b-row>
        <b-col id="action-area" md="6" offset-md="4">
          <h1 class="text-center">Sign up</h1>
          <p class="text-center" v-if="!successMessage">
            Already got an account?
            <router-link :to="{ name: 'signin' }">Sign in</router-link>
          </p>
          <div class="text-center">
            <p>{{ successMessage }}</p>
          </div>
          <div class="text-center" v-if="!successMessage">
            <p>{{ incomingMessage }}</p>
          </div>
          <div
            v-bind:class="{ cognitoError: cognitoResponse }"
            class="text-center"
            v-if="!successMessage"
          >
            {{ cognitoResponse }}
            <p v-if="showPasswordReset" class="mb-0 text-center">
              <router-link :to="{ name: 'request-password-reset' }">Forgot Password?</router-link>
            </p>
          </div>
          <b-form v-on:submit.prevent="signup" v-if="!successMessage">
            <b-form-group id="emailGroup">
              <b-form-input
                v-bind:disabled="busy"
                size="lg"
                v-model="email"
                required
                class="Control__input"
                name="email"
                type="email"
                placeholder="Email Address"
              />
            </b-form-group>
            <b-form-group id="passwordGroup">
              <b-form-input
                v-bind:disabled="busy"
                size="lg"
                v-model="password"
                required
                class="Control__input"
                name="password"
                type="password"
                placeholder="Password"
              />
            </b-form-group>
            <b-row>
              <b-button
                v-bind:disabled="busy"
                class="authButton gtm-signup"
                type="submit"
                variant="primary"
                size="lg"
                block
              >
                <div v-if="busy">
                  <Busy light :margin="0" />
                </div>
                <div v-else>Sign up</div>
              </b-button>
            </b-row>
          </b-form>
          <b-row v-if="!successMessage" class="justify-content-center my-2">
            <AuthButton
              v-if="showXero"
              :buttonImageUrl="require('@/assets/images/xero-pad.png')"
              buttonText="Sign up with Xero"
              cognitoProvider="Xero"
              v-on:updateBusy="busy += $event"
              :busy="busy"
            />
            <AuthButton
              v-if="showQuickBooks"
              :buttonImageUrl="require('@/assets/images/qb-pad.png')"
              buttonText="Sign up with QuickBooks"
              cognitoProvider="Quickbooks"
              v-on:updateBusy="busy += $event"
              :busy="busy"
            />
            <AuthButton
              v-if="showGoogle"
              :buttonImageUrl="require('@/assets/images/google_light.svg')"
              buttonText="Sign up with Google"
              cognitoProvider="Google"
              v-on:updateBusy="busy += $event"
              :busy="busy"
            />
          </b-row>
          <p class="text-center" v-if="(!$appType || $appType == 'reducer') && !successMessage">
            By signing up you agree to our
            <a href="https://reducer.co.uk/privacy" target="_blank">Privacy</a> and
            <a href="https://reducer.co.uk/terms" target="_blank">Terms</a>
          </p>
          <p class="text-center" v-if="!successMessage">
            <router-link :to="{ name: 'resend-verification' }"
              >Resend Verification Email</router-link
            >
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss">
@import "@/styles/authentication.scss";
</style>

<script>
import CognitoAuth from "@/cognito/cognitoauth";
import PasswordHelper from "@/helper/passwordhelper";
import AuthButton from "@/components/AuthButton";
import OrganisationEventHelper from "@/helper/organisationeventhelper";
import Console from "@/console";
import Busy from "@/components/Busy";
import { sso } from "@/config/reducerproperties";

export default {
  name: "Signup",
  mounted: function () {
    document.body.className = "authenticationBody";
  },
  created() {
    this.showXero = sso.xero;
    this.showQuickBooks = sso.quickbooks;
    this.showGoogle = sso.google;
  },
  destroyed: function () {
    document.body.className = "";
  },
  props: {
    incomingMessage: String
  },
  data() {
    return {
      title: "Sign Up",
      email: "",
      password: "",
      successMessage: null,
      cognitoResponse: null,
      showPasswordReset: false,
      busy: false,
      showXero: null,
      showQuickBooks: null,
      showGoogle: null
    };
  },
  components: {
    AuthButton: AuthButton,
    Busy
  },
  methods: {
    cognitoCallback(err, result) {
      Console.log(err, result);
      this.busy = false;
      if (err) {
        switch (err.code) {
          case "UsernameExistsException":
            this.cognitoResponse = "An account already exists with that email address.";
            this.showPasswordReset = true;
            break;
          case "UserLambdaValidationException":
            if (
              err.message ==
              "PreSignUp failed with error An external provider account alreadys exists for that email address."
            ) {
              this.cognitoResponse =
                "An account with that email address already exists. Please sign-in using a Single Sign On provider, such as Xero, QuickBooks or Google.";
            } else {
              this.cognitoResponse = err.message;
            }
            break;
          default:
            this.cognitoResponse = err.message;
        }
      } else {
        this.successMessage =
          "Sign up successful. We have sent you an email to verify your email address.";
      }
    },
    signup() {
      this.busy = true;
      this.cognitoResponse = null;
      this.showPasswordReset = false;
      if (PasswordHelper.passwordContainsDictionaryWord(this.password)) {
        this.busy = false;
        this.cognitoResponse = "Password cannot be a dictionary word";
      } else {
        CognitoAuth.signup(this.email.toLowerCase(), this.password, this.cognitoCallback);
      }
    }
  }
};
</script>